import Axios from "axios";
import { serverDNS } from "./contants";

Axios.defaults.withCredentials = true;
var defaultheader = {
    withCredentials: false
  };

export var showLoadingIndicator = false;

function checkError(e){
    if(e && e.response && e.response.status === 401){
        window.location.href = '/auth/login';
    }
}

export function putdata(url,data){
    showLoadingIndicator = true;
    return new Promise((resolve, reject) => {
        Axios.put(serverDNS + "api/" + url, data,defaultheader).then(response=>{ 
            showLoadingIndicator = false;
            resolve(response.data);
        },(err)=>{
            showLoadingIndicator = false;
            checkError(err);
            reject(err);
        });
    });
}


export function postdata(url,data){
    showLoadingIndicator = true;
    return new Promise((resolve, reject) => {
        Axios.post(serverDNS + "api/" +  url, data,defaultheader).then(response=>{ 
            showLoadingIndicator = false;
            resolve(response.data);
        },(err)=>{
            showLoadingIndicator = false;
            checkError(err);
            reject(err);
        });
    });
}

 
export function getData(url){
    showLoadingIndicator = true;
    return new Promise((resolve, reject) => {
        Axios.get(serverDNS + "api/"  + url,defaultheader).then(response=>{
            showLoadingIndicator = false;
            resolve(response.data);
        },(err)=>{
            showLoadingIndicator = false;
            checkError(err);
            reject(err);
        });
    });
 }
 
 export function deleteData(url){
    showLoadingIndicator = true;
    return new Promise((resolve, reject) => {
        Axios.delete(serverDNS + "api/"  + url,defaultheader).then(response=>{
            showLoadingIndicator = false;
            resolve(response.data);
        },(err)=>{
            showLoadingIndicator = false;
            checkError(err);
            reject(err);
        });
    });
 }