import React from 'react'
import { postdata, putdata } from '../../../service/httputlity'
import { notification } from '../../../service/utility'
import { PageTitle } from '../../../_metronic/layout/core'
import { IsFeatureAllowed } from '../../../service/taskservice'

export default class VMS extends React.Component {
  state = {
    newUser: '',
    role: 'user',
    UsersList: [],
    fileUpdaloaded: false,

    showaddview: false,

    Body: '',
    Status: 'Assigned',
    emailUsers: [],
    _id: '',
    getType: 'Assigned',

    addview: false,
    selectedChild: 1,
    menuList: [
      {
        icon: 'bi bi-list-task text-info menuIcon',
        id: 0,
        title: 'Tasks',
        children: [
          {
            id: 1,
            title: 'My Tasks',
          },
          {
            id: 2,
            title: 'Assigned Tasks',
          },
        ],
      },
    ],
  }

  gridjson = {
    limit: 20,
    skip: 0,
    sort: 'time',
    sortOrder: 'desc',
    selection: '',
    ParentId: '',
    getType: 'Assigned',
  }

  handleChange = this.handleChange.bind(this)

  // eslint-disable-next-line no-dupe-class-members
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  componentDidMount() {
    this.getDataFromServer()
    setTimeout(() => { }, 2000)
  }

  getDataFromServer() {
    postdata('getvmsdata', this.gridjson).then(
      (d) => {
        this.setState({
          Vision: d.vision,
          Mission: d.mission,
          Strategy: d.strategy,
          editId: d.id,
        })
      },
      (e) => { }
    )
  }

  refreshTable(params) {
    if (params) {
      this.gridjson.limit = params.limit
      this.gridjson.skip = params.skip
      this.gridjson.sort = params.sort
      this.gridjson.sortOrder = params.sortOrder
    }
    this.getDataFromServer()
  }

  timer = null
  handleSearch(event) {
    if (this.timer) {
      window.clearTimeout(this.timer)
      this.timer = null
    }
    this.setState({
      [event.target.name]: event.target.value,
    })
    this.timer = setTimeout(() => {
      var search = event.target.value.toLowerCase()
      this.gridjson.searchValue = search
      this.getDataFromServer()
    }, 1000)
  }

  saveVMS() {
    try {
      var json = {
        Vision: this.state.Vision,
        Mission: this.state.Mission,
        Strategy: this.state.Strategy,
      }
      if (this.state.editId) {
        putdata('editvms', json).then((d) => {
          notification.showSuccessMessage('Saved successfully')
          this.initFields()
        })
      } else {
        postdata('savevms', json).then((d) => {
          notification.showSuccessMessage('Saved successfully')
          this.refreshTable()
          this.initFields()
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  render() {
    const vmsBreadCrumbs = [
      {
        title: 'Vision-Mission-Strategy >',
        path: '/home/VMS',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]
    return (
      <div class='conatiner-xl h-100 users_page light_mode'>
        <PageTitle breadcrumbs={vmsBreadCrumbs}>Vision Mission Strategy</PageTitle>
        <div className={'h-100'}>
          <div class='card h-100'>
            {/* <div className='card-header border-0 pt-5'>
              <h3 className='card-title align-items-start flex-column'>
                <span className='card-label fw-bold fs-4 mb-1'>Vision Mission Strategy</span>
              </h3>
            </div> */}
            <div class='card-body p-5 h-100'>
              <div className={`row h-100 overflow-auto`}>
                <div class='input_blk h-100'>
                  <div class='form-group mb-6'>
                    <label class='form-label fs-6 fw-bold mb-4 required'>Vision</label>
                    <textarea
                      value={this.state.Vision}
                      onChange={this.handleChange}
                      name='Vision'
                      type='text'
                      class='form-control h-100 form-control-solid'
                      rows='4'
                      readOnly={!IsFeatureAllowed('vmsedit')}
                    />
                  </div>
                  <div class='form-group mb-6'>
                    <label class='form-label fs-6 fw-bold mb-4 required'>Mission</label>
                    <textarea
                      value={this.state.Mission}
                      onChange={this.handleChange}
                      name='Mission'
                      type='text'
                      class='form-control h-100 form-control-solid'
                      rows='4'
                      readOnly={!IsFeatureAllowed('vmsedit')}
                    />
                  </div>
                  <div class='form-group mb-6'>
                    <label class='form-label fs-6 fw-bold mb-4 required'>Strategy</label>
                    <textarea
                      value={this.state.Strategy}
                      onChange={this.handleChange}
                      name='Strategy'
                      type='text'
                      class='form-control h-100 form-control-solid'
                      rows='4'
                      readOnly={!IsFeatureAllowed('vmsedit')}
                    />
                  </div>
                  <button
                    type='button'
                    onClick={() => this.saveVMS()}
                    class='btn btn-success btn-sm float-right mt-4'
                  >
                    <i class="fa-regular fa-bookmark mr-2"></i>Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
