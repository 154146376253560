/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { IsFeatureAllowed, subscribeToEvent } from '../../../../service/taskservice'
import { useState } from 'react'

export function AsideMenuMain() {
  const intl = useIntl()
  const [data, setDate] = useState('0')
  subscribeToEvent('USER_DATA_UPDATE', (d: any) => {
    setDate('1')
  })
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-5 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Home</span>
        </div>
      </div>
      {IsFeatureAllowed('businessdashboard') ? <AsideMenuItem
        to='home/business'
        icon='/media/icons/fontIcon/chart-line-solid.svg'
        title='Business Dashboard'
      /> : <></>}
      <AsideMenuItem
        to='home/dashboard'
        icon='/media/icons/fontIcon/house-tsunami-solid.svg'
        title='Operations Dashboard' // {intl.formatMessage({ id: 'MENU.DASHBOARD' })}
      />

      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Pages</span>
        </div>
      </div>

      <AsideMenuItem
        to='home/tasks'
        icon='/media/icons/fontIcon/list-check-solid.svg'
        title='Tasks'
      />
      <AsideMenuItem
        to='home/reports'
        icon='/media/icons/fontIcon/square-poll-horizontal-solid.svg'
        title='Reports'
      />

      {IsFeatureAllowed('vmsview') || IsFeatureAllowed('vmsedit') ? (
        <AsideMenuItem
          to='home/VMS'
          icon='/media/icons/fontIcon/binoculars-solid.svg'
          title='Vision-Strategy'
        />
      ) : (
        <></>
      )}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Settings</span>
        </div>
      </div>
      {IsFeatureAllowed('branding') ||
        IsFeatureAllowed('accSetadd') ||
        IsFeatureAllowed('accSetedit') ||
        IsFeatureAllowed('catdelete') ||
        IsFeatureAllowed('deptadd') ||
        IsFeatureAllowed('deptedit') ||
        IsFeatureAllowed('deptdelete') ? (
        <AsideMenuItemWithSub
          to='/accounts'
          title='Account Settings'
          icon='/media/icons/fontIcon/gear-solid.svg'
        >
          {IsFeatureAllowed('branding') ? (
            <AsideMenuItem to='/account/branding' title='Branding' hasBullet={true} />
          ) : (
            <></>
          )}
          {IsFeatureAllowed('accSetadd') ||
            IsFeatureAllowed('accSetedit') ||
            IsFeatureAllowed('catdelete') ? (
            <AsideMenuItem to='/account/categories' title='Categories' hasBullet={true} />
          ) : (
            <></>
          )}
          {IsFeatureAllowed('deptadd') ||
            IsFeatureAllowed('deptedit') ||
            IsFeatureAllowed('deptdelete') ? (
            <AsideMenuItem to='/account/departments' title='Departments' hasBullet={true} />
          ) : (
            <></>
          )}
          {IsFeatureAllowed('regionadd') ||
            IsFeatureAllowed('regionedit') ||
            IsFeatureAllowed('regiondelete') ? (
            <AsideMenuItem to='/account/region' title='Region' hasBullet={true} />
          ) : (
            <></>
          )}
          {IsFeatureAllowed('subscriptionManagement') ? (
            <AsideMenuItem to='/account/subscription' title='Subscription' hasBullet={true} />
          ) : (
            <></>
          )}
        </AsideMenuItemWithSub>
      ) : (
        <></>
      )}

      {IsFeatureAllowed('subuserAdd') ||
        IsFeatureAllowed('subuserEdit') ||
        IsFeatureAllowed('subuserDelete') ||
        IsFeatureAllowed('accessControlAdd') ||
        IsFeatureAllowed('accessControlEdit') ||
        IsFeatureAllowed('accessControlDelete') ? (
        <AsideMenuItemWithSub
          to='/userManagement'
          title='User Management'
          icon='/media/icons/fontIcon/users-gear-solid.svg'
        >
          {IsFeatureAllowed('subuserAdd') ||
            IsFeatureAllowed('subuserEdit') ||
            IsFeatureAllowed('subuserDelete') ? (
            <AsideMenuItem to='/userManagement/Users' title='Users' hasBullet={true} />
          ) : (
            <></>
          )}
          {IsFeatureAllowed('accessControlAdd') ||
            IsFeatureAllowed('accessControlEdit') ||
            IsFeatureAllowed('accessControlDelete') ? (
            <AsideMenuItem
              to='/userManagement/accessControl'
              title='Access Control'
              hasBullet={true}
            />
          ) : (
            <></>
          )}
        </AsideMenuItemWithSub>
      ) : (
        <></>
      )}
      <AsideMenuItemWithSub
        to='/pages/profile'
        title='Profile Settings'
        icon='/media/icons/fontIcon/user-gear-solid.svg'
      >
        <AsideMenuItem to='/pages/profile/personalInfo' title='Personal Info' hasBullet={true} />
      </AsideMenuItemWithSub>
    </>
  )
}
