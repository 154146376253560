/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getData, postdata } from '../../../../service/httputlity'
import { serverDNS } from '../../../../service/contants'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '123',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

function loginUser(emailid: string, password: string): Promise<any> {
  if (emailid && password) {
    var json = {
      username: emailid,
      password: password,
    }

    return postdata('login', json)
  }
  return new Promise((r, e) => {
    r(null)
  })
}

export function Login() {
  const [loading, setLoading] = useState(false)
  const [invalid, setInvalid] = useState(false)
  const [step, setStep] = useState(0)

  if (window.location.href.search('invalidcredentials') != -1) {
    setInvalid(true)
  }
  getData('version').then(
    (d) => {
      if (d) {
        setTimeout(() => {
          window.location.href = '/home/dashboard'
        }, 1000)
      }
    },
    (e) => { }
  )
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting, setFieldValue }) => {
      setLoading(true)
      setInvalid(false)
      if (step == 0) {
        getData('logintype?username=' + values.email).then((d) => {
          if (d == 0) {
            setFieldValue('password', '')
            setStep(1)
          } else if (d == 1) {
            window.location.href = serverDNS + 'api/login'
          } else {
            setStatus('Email is not registered. New user ? Please Register.')
          }
          setSubmitting(false)
          setLoading(false)
        })
      } else {
        try {
          loginUser(values.email, values.password).then(
            (d) => {
              if (d && d.success) {
                window.location.href = '/home/dashboard'
              } else {
                setStatus('The login detail is incorrect')
                setSubmitting(false)
                setLoading(false)
              }
              console.log(d)
            },
            (e) => {
              console.log(e)
            }
          )
        } catch (error) {
          console.error(error)
          setStatus('The login detail is incorrect')
          setSubmitting(false)
          setLoading(false)
        }
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Sign In to ClickToDo</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
      </div>

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        <></>
      )}

      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          id='password'
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'

        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>

      {step === 1 ? (
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{ marginLeft: '5px' }}
              >
                Forgot Password ?
              </Link>
            </div>
          </div>
          <input
            type='password'

            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.password && formik.errors.password,
              },
              {
                'is-valid': formik.touched.password && !formik.errors.password,
              }
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        {invalid ? (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>Invalid Email Id or Password</span>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </form>
  )
}
