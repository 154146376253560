export var serverDNS =
  window.location.href.search('http://localhost') !== -1 ? 'http://localhost:8000/' : '/'
export var PermissionTags = [
  'dashorgLevel', //1
  'createorglevelokr', //2
  'createdeptlevelokr', //3
  'createanydeptlevelokr', //4
  'vieworglevelokr', //5
  'viewdeptlevelokr', //6
  'viewanydeptlevelokr', //7
  'editorglevelokr', //8
  'editdeptlevelokr', //9
  'editanydeptlevelokr', //10
  'deleteorglevelokr', //11
  'deletedeptlevelokr',
  'editokr', //13
  'deleteokr',
  'modifyokrlevel', //15
  'vmsview', //16
  'vmsedit', //17
  'orgtaskAdd',
  'orgtaskEdit', //19
  'orgtaskDelete',
  'reportsorgLevel', //21
  'reportsexecutiveLevel',
  'deleteanydeptlevelokr', //23
  'subuserAdd',
  'subuserEdit', //25
  'subuserDelete',
  'accessControlAdd', //27
  'accessControlEdit',
  'accessControlDelete', //29
  'branding',
  'catadd', //31
  'catedit',
  'catdelete', //33
  'deptadd',
  'deptedit', //35
  'deptdelete',
  'regionadd', //37
  'regionedit',
  'regiondelete', //39
  'accessacrossregion', //40
  'subscriptionManagement',//41
  'businessdashboard',//42
]

export var Messages = {
  access_denied: 'Access denied. Please contact your admin',
}
