import React from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'
import { Doughnut, Pie, PolarArea, Bar, Line } from 'react-chartjs-2'
import { deleteData, getData, postdata, putdata } from '../../../service/httputlity'
import { getAccountsettings } from '../../../service/utility'
import {
  ChartsWidget1,
  ChartsWidget2,
  ChartsWidget3,
  ChartsWidget4,
  ChartsWidget5,
  ChartsWidget6,
  ChartsWidget7,
  ListsWidget5,
  StatisticsWidget5,
} from '../../../_metronic/partials/widgets'
import { KTSVG } from '../../../_metronic/helpers'
import { Dropdown1 } from '../../../_metronic/partials'
import './Dashboard.scss'
import { CountryList, getAllSettings, IsFeatureAllowed, UsersList } from '../../../service/taskservice'
import { Link } from 'react-router-dom'
import taskImg from '../../../_metronic/assets/images/totaltask.png'
import activeUser from '../../../_metronic/assets/images/activeUser.jpg'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement)

export default class Dashboard extends React.Component {
  state = {
    okrDataIndividual: { data: [], totalRows: 0, totalKeys: 0 },
    okrDataOrg: { data: [], totalRows: 0, totalKeys: 0 },
    graph1: [],
    graph2: {},
    graph3: {},
    approvedTaskOrg: 0,
    approvedTaskIndi: 0,
    inprogressTaskOrg: 0,
    doneTaskIndi: 0,
    doneTaskOrg: 0,
    rejectTaskIndi: 0,
    rejectTaskOrg: 0,
    inprogressTaskIndi: 0,
    openTasksOrg: 0,
    openTasksIndi: 0,
    highPriorityTaskOrg: 0,
    highPriorityTaskIndi: 0,
    selectedChild: 1,
    currenttaskData: null,
    jobProgressData: null,
    onTimeCompleted: null,
    menuList: [],
    allTasksCountIndividual: 0,
    allTasksCountOrg: 0,
    NotOnTimePercentOrg: 'N/A',
    OnTimePercentOrg: 'N/A',
    NotOnTimePercentIndividual: 'N/A',
    OnTimePercentIndividual: 'N/A',
    dashboardSelected: 1,
    graphdrilldownlink: '/home/task',
    fname: '',
    userid: '',
    CumulativeUsers: 0,
    country: 'across_all_countries'
  }

  chartdata = {
    labels: ['Red', 'Blue'],
    datasets: [
      {
        label: '# of Votes',
        data: [12, 19],
        backgroundColor: ['#4fa39a', '#ec484a'],
        borderColor: ['#4fa39a', '#ec484a'],
        borderWidth: 1,
      },
    ],
  }

  handleChange = this.handleChange.bind(this)

  // eslint-disable-next-line no-dupe-class-members
  handleChange(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  gridjson = {
    limit: 10,
    skip: 0,
    sort: 'time',
    sortOrder: 'desc',
    selection: '',
    ParentId: '',
    getType: 0,
    search: '',
  }

  initMenu() {
    var settings = getAccountsettings()
    console.log(settings)
    this.setState({
      inprogressTaskOrg: 0,
      openTasksOrg: 0,
      approvedTaskOrg: 0,
      doneTaskOrg: 0,
      rejectTaskOrg: 0
    })
    if (!settings) {
      setTimeout(() => {
        this.initMenu()
      }, 1000)
    } else {
      this.setState({ fname: settings.fname, userid: settings.userid })
      if (IsFeatureAllowed('dashorgLevel')) {
        this.setState({ dashboardSelected: 1 });
        getData('getorgdashbaord?country=' + this.state.country).then((d) => {
          console.log(d);
          if (d) {
            var taskCount = 0
            for (var i = 0; i < d.status.length; i++) {
              if (d.status[i]._id != 'Completed') {
                taskCount += d.status[i].count
              }
            }
            // var OnTimePercent = d.okrStatus['OnTimeCompletedCount'] ? (d.okrStatus['OnTimeCompletedCount'] * 100) / d.okrStatus['TotalCount'] : ''
            // var NotOnTimePercent = d.okrStatus['NotOnTimeCount'] ? (d.okrStatus['NotOnTimeCount'] * 100) / d.okrStatus['TotalCount'] : ''
            this.setState({
              allTasksCountOrg: taskCount,
              currenttaskData: this.getCurrentTaskData(d.status, 1),
              highPriorityTaskOrg: d.priorityStatus,
              jobProgressData: this.getJobProgressStatus(d.jobprogressStatus[0]),
              OnTimePercentOrg: d.okrStatus['OnTimeCompletedCount'] ? d.okrStatus['OnTimeCompletedCount'] + '/' + d.okrStatus['TotalCount'] : d.okrStatus['TotalCount'] ? '0/' + d.okrStatus['TotalCount'] : 'NA',
              NotOnTimePercentOrg: d.okrStatus['NotOnTimeCount'] ? d.okrStatus['NotOnTimeCount'] + '/' + d.okrStatus['TotalCount'] : d.okrStatus['TotalCount'] ? '0/' + d.okrStatus['TotalCount'] : 'NA',
              CurrentMonthActiveUsers: d.ActiveUsers.currentMonth,
              CumulativeUsers: d.ActiveUsers.cumulative,
              TotalUsers: d.TotalUsers,
            })
          }
        })

        this.gridjson.getType = 1
        postdata('gettopobjectivelist?country=' + this.state.country, this.gridjson).then(
          (d) => {
            var keysCount = 0
            for (var k = 0; k < d.data.length; k++) {
              keysCount += d.data[k].ObjectiveKeys.length
            }
            this.setState({
              okrDataOrg: { data: d.data.slice(0, 10), totalRows: d.totalRows, totalKeys: keysCount },
            })
          },
          (e) => { }
        )
      } else {
        this.setState({ dashboardSelected: 2 })
      }
    }
  }

  componentDidMount() {
    this.initMenu();
    getData('getindidashbaord').then((d) => {
      console.log(d);
      if (d) {
        var taskCount = 0
        for (var i = 0; i < d.status.length; i++) {
          if (d.status[i]._id !== 'Completed') {
            taskCount += d.status[i].count
          }
        }
        // var OnTimePercent = d.okrStatus['OnTimeCompletedCount'] ? (d.okrStatus['OnTimeCompletedCount'] * 100) / d.okrStatus['TotalCount'] : ''
        // var NotOnTimePercent = d.okrStatus['NotOnTimeCount'] ? (d.okrStatus['NotOnTimeCount'] * 100) / d.okrStatus['TotalCount'] : ''
        this.setState({
          allTasksCountIndividual: taskCount,
          AssignedByMe: this.getCurrentTaskData(d.iAssignedTask),
          highPriorityTaskIndi: d.priorityStatus,
          AssingedToMe: this.getCurrentTaskData(d.myStatus, 2),
          OnTimePercentIndividual: d.okrStatus['OnTimeCompletedCount'] ? d.okrStatus['OnTimeCompletedCount'] + '/' + d.okrStatus['TotalCount'] : d.okrStatus['TotalCount'] ? '0/' + d.okrStatus['TotalCount'] : 'NA',
          NotOnTimePercentIndividual: d.okrStatus['NotOnTimeCount'] ? d.okrStatus['NotOnTimeCount'] + '/' + d.okrStatus['TotalCount'] : d.okrStatus['TotalCount'] ? '0/' + d.okrStatus['TotalCount'] : 'NA',
        })
      }
    })
    // getData('getdashboard1?type=1').then(
    //   (d) => {
    //     var taskCount = 0
    //     for (var i = 0; i < d.length; i++) {
    //       taskCount += d[i].count
    //     }
    //     this.setState({ allTasksCountIndividual: taskCount })
    //   },
    //   (e) => { }
    // )
    // getData('getprioritycount?type=1').then(
    //   (d) => {
    //     if (d) {
    //       this.setState({
    //         highPriorityTaskIndi: d,
    //       })
    //     }
    //   },
    //   (e) => { }
    // )
    // getData('getdashboard4').then(
    //   (d) => {
    //     this.setState({ AssignedByMe: this.getCurrentTaskData(d) })
    //   },
    //   (e) => { }
    // )
    // getData('getdashboard5').then(
    //   (d) => {
    //     this.setState({ AssingedToMe: this.getCurrentTaskData(d, 2) })
    //   },
    //   (e) => { }
    // )
    this.getOkrData()
  }

  getOkrData() {
    this.gridjson.getType = 0
    postdata('gettopobjectivelist', this.gridjson).then(
      (d) => {
        var keysCount = 0
        for (var k = 0; k < d.data.length; k++) {
          keysCount += d.data[k].ObjectiveKeys.length
        }
        this.setState({
          okrDataIndividual: {
            data: d.data.slice(0, 10),
            totalRows: d.totalRows,
            totalKeys: keysCount,
          },
        })
      },
      (e) => { }
    )
  }
  getAssingedByMeGraph(data) {
    if (data) {
      return {
        labels: ['On Time Completed', 'Not Completed On Time'],
        datasets: [
          {
            label: '# of Tasks',
            data: [data.completedontime, data.notcompletedontime],
            backgroundColor: ['#0071e3', '#ec484a'],
            borderColor: ['#0071e3', '#ec484a'],
            borderWidth: 1,
          },
        ],
      }
    }
  }

  getAssingedToMeGraph(data) {
    if (data) {
      return {
        labels: ['On Time Completed', 'Not Completed On Time'],
        datasets: [
          {
            label: '# of Tasks',
            data: [data.completedontime, data.notcompletedontime],
            backgroundColor: ['#4fa39a ', '#61c7b8 '],
            borderColor: ['#4fa39a', '#61c7b8'],
            borderWidth: 1,
          },
        ],
      }
    }
  }

  getCurrentTaskData(d, dashboardId) {
    if (d) {
      var label = [
        'Waiting on Third Party',
        'Waiting on Customer',
        'Done',
        'Approved',
        'In Progress',
        'Open',
        'Reject',
      ]
      var data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
      for (var i = 0; i < d.length; i++) {
        var index = label.indexOf(d[i]._id)
        data[index] = d[i].count
      }

      if (dashboardId === 1) {
        for (var i = 0; i < d.length; i++) {
          switch (d[i]._id) {
            case 'In Progress':
              this.setState({
                inprogressTaskOrg: d[i].count,
              })
              break
            case 'Open':
              this.setState({
                openTasksOrg: d[i].count,
              })
              break
            case 'Completed':
              this.setState({
                approvedTaskOrg: d[i].count,
              })
              break
            case 'Done':
              this.setState({
                doneTaskOrg: d[i].count,
              })
              break;
            case 'Reject':
              this.setState({
                rejectTaskOrg: d[i].count,
              })
              break
            default:
              break
          }
        }
      } else if (dashboardId == 2) {
        for (var i = 0; i < d.length; i++) {
          switch (d[i]._id) {
            case 'In Progress':
              this.setState({
                inprogressTaskIndi: d[i].count,
              })
              break
            case 'Open':
              this.setState({
                openTasksIndi: d[i].count,
              })
              break
            case 'Completed':
              this.setState({
                approvedTaskIndi: d[i].count,
              })
              break
            case 'Done':
              this.setState({
                doneTaskIndi: d[i].count,
              })
              break
            case 'Reject':
              this.setState({
                rejectTaskIndi: d[i].count,
              })
              break
            default:
              break
          }
        }
      }

      return {
        labels: label,
        fill: true,
        datasets: [
          {
            label: '# of Tasks',
            data: data,
            backgroundColor: [
              '#1a8cff',
              '#55a6f7',
              '#00ddff',
              '#009900',
              '#00cc00',
              '#85e085',
              '#ff6666',
              '#fdc04e',
              '#e68a00',
              '#f07577',
              '#ff6666',
            ],
            borderColor: [
              '#1a8cff',
              '#55a6f7',
              '#00ddff',
              '#009900',
              '#5cd65c',
              '#fdc04e',
              '#ff6666',
              '#e68a00',
              '#f07577',
              '#ff6666',
            ],
            borderWidth: 1,
          },
        ],
      }
    }
  }

  getJobProgressStatus(data) {
    if (data) {
      return {
        labels: ['On Time ', 'Delayed'],
        datasets: [
          {
            label: 'On Time',
            data: [data.completedontime, data.notcompletedontime],
            backgroundColor: ['#50cd89', '#339cff '],
            borderColor: ['rgba(255, 255, 255, 1)', 'rgba(255, 255, 255, 1)'],
            borderWidth: 1,
          },
        ],
      }
    }
  }

  getOnTimeCompletedStatus(data) {
    if (data) {
      return {
        labels: ['On Time ', 'Delayed'],
        datasets: [
          {
            label: '# of Tasks',
            data: [data.completedontime, data.notcompletedontime],
            backgroundColor: ['#0071e3', '#ec484a'],
            borderColor: ['#0071e3', '#ec484a'],
            borderWidth: 1,
          },
        ],
      }
    }
  }

  sidebarItemsClicked(id) {
    this.setState({ selectedChild: id })
  }

  plugin = {
    maintainAspectRatio: true,
    width: '400',
    height: '400',
    legend: {
      labels: {
        boxHeight: 8,
        boxWidth: 8,
        usePointStyle: true,
        fontColor: '#006192',
      },
      display: true,
      position: 'left',
      textAlign: 'left',
      fontFamily: 'Allianz-Neo',
      textDirection: 'ltr',
    },
  }

  options1 = {
    responsive: true,
    plugins: this.plugin,
    onClick: this.graphClick1,
  }

  options = {
    responsive: true,
    plugins: this.plugin,
    onClick: function (evnt, ele) {
      this.graphClick(evnt, ele)
    },
  }

  graphClick1 = this.graphClick1.bind(this)
  graphClick1(evt, element) {
    if (element.length > 0) {
      var ind = element[0].index
      let url =
        'search=' + btoa(evt.chart.config._config.data.labels[ind]) + '&type=' + btoa('OrgLevel')
      window.location.href = '/home/tasks?' + url
    }
  }

  detaildViewClicked(row) {
    this.props.showOKRDetails(row, true)
  }

  render() {
    var graph1, graph2, graph3, graph4
    if (this.state.currenttaskData) {
      graph1 = <Doughnut data={this.state.currenttaskData} options={this.options1} />
    } else {
      graph1 = (
        <div class='nodata text-secondary'>
          <i class='bi bi-file-earmark-excel icon'></i>
          <div class='content'>No Data Available</div>
        </div>
      )
    }
    if (this.state.jobProgressData) {
      let option = JSON.parse(JSON.stringify(this.options))
      option['onClick'] = function (evt, element) {
        if (element.length > 0) {
          var ind = element[0].index
          //Approved Task Statistics -- org level
          let url =
            'search=' +
            btoa(evt.chart.config._config.data.labels[ind]) +
            '&type=' +
            btoa('OrgLevel')
          window.location.href = '/home/tasks?' + url

        }
      }
      graph2 = (
        <Pie data={this.state.jobProgressData} options={option} width={100} height={50}></Pie>
      )
    } else {
      graph2 = (
        <div class='nodata text-secondary'>
          <i class='bi bi-file-earmark-excel icon'></i>
          <div class='content'>No Data Available</div>
        </div>
      )
    }
    if (this.state.AssignedByMe) {
      let option = JSON.parse(JSON.stringify(this.options))
      option['onClick'] = function (evt, element) {
        if (element.length > 0) {
          var ind = element[0].index
          //Task Created By Me Status -- individual
          let url =
            'search=' +
            btoa(evt.chart.config._config.data.labels[ind]) +
            '&type=' +
            btoa('Created')
          window.location.href = '/home/tasks?' + url

        }
      }
      graph3 = <Bar data={this.state.AssignedByMe} options={option}></Bar>
    } else {
      graph3 = (
        <div class='nodata text-secondary'>
          <i class='bi bi-file-earmark-excel icon'></i>
          <div class='content'>No Data Available</div>
        </div>
      )
    }
    if (this.state.AssingedToMe) {
      let option = JSON.parse(JSON.stringify(this.options))
      option['onClick'] = function (evt, element) {
        if (element.length > 0) {
          var ind = element[0].index
          let url =
            'search=' +
            btoa(evt.chart.config._config.data.labels[ind]) +
            '&type=' +
            btoa('Assigned')
          window.location.href = '/home/tasks?' + url
          //My Assigned Task Status -- individual

        }
      }
      graph4 = <Doughnut data={this.state.AssingedToMe} options={option}></Doughnut>
    } else {
      graph4 = (
        <div class='nodata text-secondary'>
          <i class='bi bi-file-earmark-excel icon'></i>
          <div class='content'>No Data Available</div>
        </div>
      )
    }
    var menuList = this.state.menuList.map((item) => {
      if (item.children) {
        var menuChild = item.children.map((child) => {
          return (
            <li>
              <a
                className={`child ${this.state.selectedChild === child.id ? 'selected' : ''}`}
                onClick={() => {
                  this.sidebarItemsClicked(child.id)
                }}
              >
                <span>{child.title}</span>
                <i class='bi bi-caret-right-fill menuIcon'></i>
              </a>
            </li>
          )
        })
      }
      return (
        <li>
          <a
            className={`parent arrow-down ${this.state.selectedChild === item.id ? 'selected' : ''
              }`}
            onClick={() => {
              this.sidebarItemsClicked(item.id)
            }}
          >
            <i class={item.icon}></i> <span>{item.title}</span>
          </a>
          <ul className={`sub-menu ${menuChild ? '' : 'd-none'}`}>{menuChild}</ul>
        </li>
      )
    })
    let OKRData =
      this.state.dashboardSelected == 2 ? this.state.okrDataIndividual : this.state.okrDataOrg
    return (
      <>
        <div className='row justify-content-end mb-3'>
          <div className={'col-xl-3 col-md-4 col-xs-6 d-flex align-items-center justify-content-end' + (IsFeatureAllowed('dashorgLevel') ? '' : 'hide')}>
            <label className='mr-3 mb-0 fw-bold'>Country</label>
            <select
              className={`btn btn-sm btn-bg-light btn-color-dark btn-outline ${IsFeatureAllowed('dashorgLevel') && IsFeatureAllowed('accessacrossregion') && this.state.dashboardSelected === 1 ? '' : 'hide'}`}
              value={this.state.country}
              onChange={(event) => {
                this.setState({ country: event.target.value })
                setTimeout(() => {
                  this.initMenu()
                }, 200)
              }}
            >
              <option value="across_all_countries">Across All Counties</option>
              {CountryList.map((x => {
                return <option value={x.region_name}>{x.region_name}</option>
              }))}
            </select>
          </div>
          <div
            class='btn-group col-xl-4 col-md-6 col-xs-8'
            data-kt-buttons='true'
            data-kt-buttons-target='[data-kt-button]'
          >
            <label
              className={`btn btn-sm ${this.state.dashboardSelected === 1
                ? 'btn-active-success active'
                : 'btn-bg-light btn-color-dark btn-outline'
                } ${IsFeatureAllowed('dashorgLevel') ? '' : 'hide'}`}
              data-kt-button='true'
            >
              <input
                class='btn-check'
                type='radio'
                name='level'
                value='1'
                onChange={() => {
                  this.setState({ dashboardSelected: 1 })
                }}
              />
              Organization Level
            </label>
            <label
              className={`btn btn-sm ${this.state.dashboardSelected === 2
                ? 'btn-active-success active'
                : 'btn-bg-light btn-color-dark btn-outline'
                }`}
              data-kt-button='true'
            >
              <input
                class='btn-check'
                type='radio'
                name='level'
                value='2'
                onChange={() => {
                  this.setState({ dashboardSelected: 2 })
                }}
              />
              Individual Level
            </label>
          </div>
        </div>
        <div class='row g-5 g-xl-10 mb-5 mb-xl-10'>

          <div className='col-xl-12'>
            <div class='card card-flush h-xl-100 bg_prple_dark '>
              <div class='card-body p-5 '>
                <div class='row align-items-center h-100'>

                  <div className={this.state.dashboardSelected === 2 ? 'hide' : 'col-xl-6'}>

                    <Link to='/userManagement/Users' class='card card-flush h-xl-100 w-100  border border-white-300 border-dashed onhover'>
                      <div class='card-header pt-2'>
                        <h3 class='card-title align-items-start flex-column'>
                          <span class='card-label fw-bold text-dark'>Active users</span>
                        </h3>
                      </div>
                      <div class='card-body p-5 d-flex align-items-end pt-2'>
                        <div class='row align-items-center mx-0 w-100'>
                          <div className='col-md-8'>
                            <div class='d-flex flex-column content-justify-center'>
                              <div class='d-flex fs-6 fw-semibold align-items-center'>
                                <div class='me-3 rounded'>
                                  <i class="fa-solid fa-users text-success fs-3"></i>
                                </div>
                                <div class='fs-5 fw-bold text-gray-600 me-5'>Total :</div>
                                <div class='ms-auto fs-3 fw-bolder text-gray-700 text-end'>
                                  {this.state.TotalUsers}
                                </div>
                              </div>
                              <div class='d-flex fs-6 fw-semibold align-items-center my-2'>
                                <div class='me-3 rounded'>
                                  <i class="fa-solid fa-arrow-up-right-dots text-primary fs-3"></i>
                                </div>
                                <div class='fs-5 fw-bold text-gray-600 me-5 text_extract'
                                  data-bs-toggle='tooltip'
                                  data-bs-trigger='hover'
                                  data-bs-dismiss-='click'
                                  title='Cumulative'>Cumulative :</div>
                                <div class='ms-auto fs-3 fw-bolder text-gray-700 text-end'>
                                  {this.state.CumulativeUsers}
                                </div>
                              </div>
                              <div class='d-flex fs-6 fw-semibold align-items-center'>
                                <div class='me-3 rounded'>
                                  <i class="fa-solid fa-calendar-check text-danger fs-3"></i>
                                </div>
                                <div class='fs-5 fw-bold text-gray-600 me-5'>Monthly :</div>
                                <div class='ms-auto fs-3 fw-bolder text-gray-700 text-end'>
                                  {this.state.CurrentMonthActiveUsers}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class='col-md-4'>
                            <img src={activeUser} alt="user Img" width='100%' className='d-xs-none' />
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  {/*<div className={this.state.dashboardSelected === 1 ? 'hide' : 'col-xl-3'}>
                    <Link to='/userManagement/Users' class='card card-flush h-xl-100 box_shadow1  border border-white-300 border-dashed onhover'>
                      <div class='card-header pt-2'>
                        <h3 class='card-title align-items-start flex-column'>
                          <span class='card-label fw-bold text-dark'>User details</span>
                        </h3>
                      </div>
                      <div class='card-body p-5 d-flex align-items-end pt-2'>
                        <div class='row align-items-center mx-0 w-100'>
                          <div class='d-flex flex-column content-justify-center'>
                            <div class='d-flex fs-6 fw-semibold align-items-center'>
                              <div class='me-3 rounded'>
                                <i class="fa-solid fa-user fs-3"></i>
                              </div>
                              <div class='fs-6 fw-bold text-gray-600 me-5 text_extract'
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-dismiss-='click'
                                title={this.state.fname}
                              >{this.state.fname}</div>
                            </div>
                            <div class='d-flex fs-6 fw-semibold align-items-center my-4'>
                              <div class='me-3 rounded'>
                                <i class="fa-solid fa-envelope text-success fs-4"></i>
                              </div>
                              <div class='fs-8 fw-semibold text-gray-600 me-5 text_extract'
                                data-bs-toggle='tooltip'
                                data-bs-trigger='hover'
                                data-bs-dismiss-='click'
                                title={this.state.userid}
                              >{this.state.userid}</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                   <div className={(this.state.dashboardSelected !== 1 && this.state.dashboardSelected !== 2) ? 'hide' : 'col-xl-3'}>
                  </div>
                  <div className={'col-xl-3'}>
                  </div> */}
                  <div className={'col-xl-6 h-100'}>
                    <Link
                      to={
                        '/home/tasks?search=&type=' +
                        btoa(this.state.dashboardSelected === 2 ? 'Assigned' : 'OrgLevel')
                      }
                      className='cursor-pointer'
                    >
                      <div class='d-flex align-items-center border border-white-300 border-dashed onhover rounded d-flex align-items-centerr justify-content-around h-100 p-5'>
                        <div class='symbol symbol-40px mr-4'>
                          <i class='fa-solid fa-list-check text-white fs-2qx'></i>
                        </div>
                        <div class='me-2 my-2'>
                          <span class='text-white fw-bolder d-block fs-2qx lh-1 ls-n1 mb-5'>
                            {this.state.dashboardSelected === 2
                              ? this.state.allTasksCountIndividual
                              : this.state.allTasksCountOrg}
                          </span>
                          <span class='text-white fw-semibold fs-2 mb-2 mt-5'>Total Tasks</span>
                        </div>
                        <div class='symbol symbol-150px'>
                          <img src={taskImg} alt="task Img" width='100%' className='d-xs-none' />
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-xl-12'>
            <div className='row g-5'>
              <div className='col-xl-3'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8 h-200px'
                  svgIcon='/media/icons/fontIcon/book-open-solid.svg'
                  color='primary'
                  iconColor='white'
                  title='Open Tasks'
                  description='From Past 1 Month'
                  titleColor='white'
                  descriptionColor='white'
                  value={
                    this.state.dashboardSelected === 2
                      ? this.state.openTasksIndi
                      : this.state.openTasksOrg
                  }
                  link={
                    '/home/tasks?search=' +
                    btoa('Open') +
                    '&type=' +
                    btoa(this.state.dashboardSelected === 2 ? 'Assigned' : 'OrgLevel')
                  }
                />
              </div>
              <div className='col-xl-3'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-xl-8 h-200px'
                  svgIcon='/media/icons/fontIcon/bars-progress-solid.svg'
                  color='warning bg-orange-600'
                  iconColor='white'
                  title='In Progress Tasks'
                  description='From Past 1 Month'
                  titleColor='white'
                  descriptionColor='white'
                  value={
                    this.state.dashboardSelected === 2
                      ? this.state.inprogressTaskIndi
                      : this.state.inprogressTaskOrg
                  }
                  link={
                    '/home/tasks?search=' +
                    btoa('In Progress') +
                    '&type=' +
                    btoa(this.state.dashboardSelected === 2 ? 'Assigned' : 'OrgLevel')
                  }
                />
              </div>
              <div className='col-xl-3'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-5 mb-xl-8'
                  svgIcon='/media/icons/fontIcon/square-arrow-up-right-solid.svg'
                  iconCls='bi bi-list-task'
                  color='danger'
                  iconColor='white'
                  title='Rejected Tasks'
                  description='From Past 1 Month'
                  titleColor='white'
                  descriptionColor='white'
                  value={
                    this.state.dashboardSelected === 2
                      ? this.state.rejectTaskIndi
                      : this.state.rejectTaskOrg
                  }
                  link={
                    '/home/tasks?search=' +
                    btoa('Reject') +
                    '&type=' +
                    btoa(this.state.dashboardSelected === 2 ? 'Assigned' : 'OrgLevel')
                  }
                />
              </div>
              <div className='col-xl-3'>
                <StatisticsWidget5
                  className='card-xl-stretch mb-5 mb-xl-8'
                  svgIcon='/media/icons/fontIcon/clipboard-check-solid.svg'
                  color='info'
                  iconColor='white'
                  title='Completed Tasks'
                  description='From Past 1 Month'
                  titleColor='white'
                  descriptionColor='white'
                  value={
                    this.state.dashboardSelected === 2
                      ? this.state.approvedTaskIndi
                      : this.state.approvedTaskOrg
                  }
                  link={
                    '/home/tasks?search=' +
                    btoa('Approved,Done') +
                    '&type=' +
                    btoa(this.state.dashboardSelected === 2 ? 'Assigned' : 'OrgLevel')
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className='row g-5 g-xxl-8'>
          <div className={this.state.dashboardSelected === 2 ? 'hide' : 'col-xl-6'}>
            <div className='card mb-5 mb-xxl-8'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-1'>Task Status</span>
                  {/* <span className='text-muted fw-semibold fs-7'>More than 400 new members</span> */}
                </h3>
                <div className='card-toolbar'>
                  <button className='btn btn-sm btn-color-info '>Organization Level</button>
                </div>
              </div>
              <div className='card-body chart_body'>
                <div className='chart_div'>{graph1}</div>
              </div>
            </div>
          </div>
          <div className={this.state.dashboardSelected === 2 ? 'hide' : 'col-xl-6'}>
            <div className='card mb-5 mb-xxl-8'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-1'>Approved Task Statistics:</span>
                  {/* <span className='text-muted fw-semibold fs-7'>More than 400 new members</span> */}
                </h3>
                <div className='card-toolbar'>
                  <button className='btn btn-sm btn-color-info '>Organization Level</button>
                </div>
              </div>
              <div className='card-body chart_body'>
                <div className='chart_div'>{graph2}</div>
              </div>
            </div>
          </div>
          <div className={this.state.dashboardSelected === 1 ? 'hide' : 'col-xl-6'}>
            <div className='card mb-5 mb-xxl-8'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-1'>My Assigned Task Status</span>
                  {/* <span className='text-muted fw-semibold fs-7'>More than 400 new members</span> */}
                </h3>
                <div className='card-toolbar'>
                  <button className='btn btn-sm btn-color-info'>Individual Task</button>
                </div>
              </div>
              <div className='card-body chart_body'>
                <div className='chart_div'>{graph4}</div>
              </div>
            </div>
          </div>
          <div className={this.state.dashboardSelected === 1 ? 'hide' : 'col-xl-6'}>
            <div className='card mb-5 mb-xxl-8'>
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label fw-bold fs-4 mb-1'>Task Created By Me Status</span>
                  {/* <span className='text-muted fw-semibold fs-7'>More than 400 new members</span> */}
                </h3>
                <div className='card-toolbar'>
                  <button className='btn btn-sm btn-color-info '>Individual Task</button>
                </div>
              </div>
              <div className='card-body chart_body'>
                <div className='chart_div'>{graph3}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
